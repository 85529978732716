.subheader {
  //margin-bottom: calc(1.5rem + 0.625rem);
  margin-bottom: 1rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.subheader-title small {
  display: block;
  font-weight: 400;
  color: #838383;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.subheader-title {
  font-size: 1.375rem;
  font-weight: 500;
  color: #505050;
  text-shadow: #fff 0 1px;
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
