/*---------------------------------------------------------------------------------------------*
 * Colors                                                                                      *
 *---------------------------------------------------------------------------------------------*/
$pd: #1e1e1f;
$t1: #fed352;
$t2: #f39402;
$t3: #e46c6d;
$d1: #02bbca;
$d2: #5ee4f1;
$dt: #f2f2f2;

/*---------------------------------------------------------------------------------------------*
 * Mix Ins                                                                                     *
 *---------------------------------------------------------------------------------------------*/
@mixin darkenColor($color) {
  fill: darken($color, 30%);
}

/*---------------------------------------------------------------------------------------------*
 * General                                                                                     *
 *---------------------------------------------------------------------------------------------*/
tspan.sub {
  font-size: 0.75em;
}

text.small {
  font-size: 0.8em;
}

circle.plot-point {
  fill: #fefefe;
  stroke: #1e1e1f;
  stroke-width: 1px;
}

svg.duval-triangle {
  .axis-labels {
    font-family: 'Hepta Slab', serif;
    font-weight: 400;
  }
}

/*---------------------------------------------------------------------------------------------*
 * Areas                                                                                       *
 *---------------------------------------------------------------------------------------------*/
.duval-triangle {
  polygon.pd {
    fill: $pd;
  }

  polygon.t1 {
    fill: $t1;
  }

  polygon.t2 {
    fill: $t2;
  }

  polygon.t3 {
    fill: $t3;
  }

  polygon.d1 {
    fill: $d1;
  }

  polygon.d2 {
    fill: $d2;
  }

  polygon.dt {
    fill: $dt;
  }

  .axes line {
    stroke: #333333;
  }

  .area-labels {
    .pd {
      @include darkenColor($pd);
    }

    .d1 {
      @include darkenColor($d1);
    }

    .d2 {
      @include darkenColor($d2);
    }

    .dt {
      @include darkenColor($dt);
    }

    .t1 {
      @include darkenColor($t1);
    }

    .t2 {
      @include darkenColor($t2);
    }

    .t3 {
      @include darkenColor($t3);
    }

    .area-label-line {
      stroke: #000000;
    }
  }
}
