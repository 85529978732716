/*---------------------------------------------------------------------------------------------*
 * Colors                                                                                      *
 *---------------------------------------------------------------------------------------------*/
$color-success: #28a745;
$color-danger: #dc3545;
$color-warning: #ffc107;

/*---------------------------------------------------------------------------------------------*
 * Content Blocks                                                                              *
 *---------------------------------------------------------------------------------------------*/
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
  background-color: #faf8fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*---------------------------------------------------------------------------------------------*
 * Containers                                                                                  *
 *---------------------------------------------------------------------------------------------*/
.container {
  margin: 0 auto;
  min-width: 640px;
  max-width: 1024px;
}

/*---------------------------------------------------------------------------------------------*
 * Panel                                                                                       *
 *---------------------------------------------------------------------------------------------*/
.panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 0 13px 0 rgba(62, 44, 90, 0.08);
  box-shadow: 0 0 13px 0 rgba(62, 44, 90, 0.08);
  margin-bottom: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
  -webkit-transition: border 0.5s ease-out;
  transition: border 0.5s ease-out;
}

.panel-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  min-height: 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px 4px 0 0;
  -webkit-transition: background-color 0.4s ease-out;
  transition: background-color 0.4s ease-out;
}

.panel-header h2 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 0.875rem;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 3rem;
  color: inherit;
  color: #333;
  position: relative;
  font-weight: 500;
}

.panel-header h2:not(:only-child) {
  margin-right: 0.66667rem;
}
.panel-header > :first-child {
  padding-left: 1rem;
}

.panel-container {
  display: flex;
  border-radius: 0 0 4px 4px;
  padding: 1rem;
}

.panel-container.faded-bg {
  background-image: linear-gradient(to top, #fff, #f5fcff);
}

.btn-container {
  justify-content: flex-end;
}

.border-faded {
  border: 1px solid rgba(29, 29, 29, 0.07);
}
.bg-faded {
  background-color: #f7f9fa;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}

// /*---------------------------------------------------------------------------------------------*
//  * Buttons                                                                                     *
//  *---------------------------------------------------------------------------------------------*/
// .btn {
//   display: inline-block;
//   font-weight: 400;
//   color: #212529;
//   text-align: center;
//   vertical-align: middle;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: 2px solid transparent;
//   padding: 0.5rem 1.125rem;
//   font-size: 0.8125rem;
//   line-height: 1.47;
//   border-radius: 4px;
//   -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
//     -webkit-box-shadow 0.15s ease-in-out;
// }

// .btn,
// .btn.active.focus,
// .btn.active:focus,
// .btn.focus,
// .btn.focus:active,
// .btn:active:focus,
// .btn:focus,
// a,
// a:active,
// a:focus,
// button,
// button:active,
// button:focus {
//   outline: 0;
// }

// .btn:active {
//   -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset !important;
// }

// .btn:hover {
//   color: #212529;
//   text-decoration: none;
// }

// [type='button'],
// [type='reset'],
// [type='submit'],
// button {
//   -webkit-appearance: button;
// }
// [role='button'],
// a,
// area,
// button,
// input,
// label,
// select,
// summary,
// textarea {
//   -ms-touch-action: manipulation;
//   touch-action: manipulation;
// }
// button,
// select {
//   text-transform: none;
// }

// .btn.focus,
// .btn:focus {
//   outline: 0;
//   -webkit-box-shadow: 0 0 0 0.2rem transparent;
//   box-shadow: 0 0 0 0.2rem transparent;
// }

// .btn-primary.focus,
// .btn-primary:focus {
//   -webkit-box-shadow: 0 0 0 0.2rem rgba(154, 128, 192, 0.5);
//   box-shadow: 0 0 0 0.2rem rgba(154, 128, 192, 0.5);
// }

// .btn-primary {
//   color: #fff;
//   background-color: #886ab5;
//   border-color: #886ab5;
// }

// .btn-primary:hover {
//   color: #fff;
//   background-color: #7453a6;
//   border-color: #6e4e9e;
// }

// .btn-primary:not(:disabled):not(.disabled).active,
// .btn-primary:not(:disabled):not(.disabled):active,
// .show > .btn-primary.dropdown-toggle {
//   color: #fff;
//   background-color: #6e4e9e;
//   border-color: #684a95;
// }

// .btn-success {
//   -webkit-box-shadow: 0 2px 6px 0 rgba(29, 201, 183, 0.5);
//   box-shadow: 0 2px 6px 0 rgba(29, 201, 183, 0.5);
//   color: #fff;
//   background-color: #1dc9b7;
//   border-color: #1dc9b7;
// }

// .btn-success:not(:disabled):not(.disabled).active:focus,
// .btn-success:not(:disabled):not(.disabled):active:focus,
// .show > .btn-success.dropdown-toggle:focus {
//   -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
//   box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
// }
// .btn-success:not(:disabled):not(.disabled).active,
// .btn-success:not(:disabled):not(.disabled):active,
// .show > .btn-success.dropdown-toggle {
//   color: #fff;
//   background-color: #179c8e;
//   border-color: #159184;
// }
// .btn-success.focus,
// .btn-success:focus {
//   -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
//   box-shadow: 0 0 0 0.2rem rgba(63, 209, 194, 0.5);
// }
// .btn-success:hover {
//   color: #fff;
//   background-color: #18a899;
//   border-color: #179c8e;
// }

// .btn-pills {
//   border-radius: 15px;
// }

// .waves-effect {
//   position: relative;
//   cursor: pointer;
//   display: inline-block;
//   overflow: hidden;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-tap-highlight-color: transparent;
// }

// [type='button']:not(:disabled),
// [type='reset']:not(:disabled),
// [type='submit']:not(:disabled),
// button:not(:disabled) {
//   cursor: pointer;
// }

/*---------------------------------------------------------------------------------------------*
 * Forms                                                                                       *
 *---------------------------------------------------------------------------------------------*/
.form-control {
  //display: block;
  width: 100%;
  padding: 0.5rem 0.875rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.47;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #886ab5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem transparent;
  box-shadow: 0 0 0 0.2rem transparent;
}

.form-control.error {
  border-color: $color-danger;
}

.error-message {
  font-size: 0.7rem;
  color: $color-danger;
}

/*---------------------------------------------------------------------------------------------*
 * Misc                                                                                        *
 *---------------------------------------------------------------------------------------------*/
.serif {
  font-family: 'Hepta Slab', serif;
}

.p-0 {
  padding: 0;
}

.m-l-5 {
  margin-left: 5px;
}

.full-width {
  width: 100%;
}

span.bold {
  font-weight: 500;
}
