.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  background-color: #3f4d7a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.app-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1rem;
  padding-right: 1rem;
}

.app-footer {
  background-color: #282c38;
  color: #757f9e;
  height: 50px;
  padding: 5px;
  text-align: right;
}

.app-logo {
  font-size: 1.3rem;
  color: white;
  padding-left: 1.75rem;
}

.app-link {
  color: #61dafb;
}

.app-link:hover {
  text-decoration: underline;
}
