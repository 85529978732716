.result-set {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  font-size: 0.9em;

  h3 {
    font-family: 'Hepta Slab', serif;
    font-weight: 400;
  }
}

div.empty-result-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  background: #ffffff;
  border-radius: 5px;
  border: 2px dashed rgba(136, 106, 181, 0.5);

  h3 {
    font-weight: 400;
    color: #999999;
  }
}
