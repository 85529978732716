.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.calculator-header {
  font-size: 20px;
  color: #282c38;
}

.calculator-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 5px;
}

.calculator-form > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calculator-form > ul,
.calculator-form > li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
}

.calculator-form > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
  width: 100%;
}

.calculator-form > ul > li > label {
  flex: 1 1 185px;
  max-width: 220px;
}

.calculator-form > ul > li > label + input {
  flex: 1 0 80px;
  max-width: 80px;
}
